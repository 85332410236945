import Album from '../core/photographyAlbum';
import Photograph from '../core/photograph';
import Text from '../core/text';
import Genre from '../core/genre';

const ConqueringAmerica = new Album(
  new Text('ConqueringAmerica.Title', 'Conquering America'),
  Genre.Street,
  new Date('2016-11-05'),
  new Photograph('/photographs/2016-10-15_11-05/2016-10-15_11-05_019_large.webp', false,
  new Text('ConqueringAmerica.', 'National Mall\n\nThe long, grassy National Mall is home to iconic monuments including the Lincoln Memorial and the Washington Monument. At the eastern end is the domed U.S. Capitol, and the White House is to the north. It\'s also flanked by Smithsonian museums, and its lawns and pathways are often crowded with school groups, joggers, and softball teams. Nearby, the Tidal Basin reservoir is known for its blossoming cherry trees.')),
  [
    new Photograph('/photographs/2016-10-15_11-05/2016-10-15_11-05_001_large.webp', true),
    new Photograph('/photographs/2016-10-15_11-05/2016-10-15_11-05_002_large.webp'),
    new Photograph('/photographs/2016-10-15_11-05/2016-10-15_11-05_003_large.webp', true,
      new Text('ConqueringAmerica.', 'One World Trade Center\n\nOne World Trade Center is the main building of the rebuilt World Trade Center complex in Lower Manhattan. One WTC is the tallest building in the United States, the tallest building in the Western Hemisphere, and the seventh-tallest in the world. The supertall structure has the same name as the North Tower of the original World Trade Center, which was destroyed in the terrorist attacks of September 11, 2001. The new skyscraper stands on the northwest corner of the 6.5 ha World Trade Center site, on the site of the original 6 World Trade Center.')),
    new Photograph('/photographs/2016-10-15_11-05/2016-10-15_11-05_004_large.webp', false,
      new Text('ConqueringAmerica.', 'Shoeshiner at work at Wall Street\n\nWhile the role is denigrated in much of Western civilization, shining shoes is an important source of income for many children and families throughout the world. Some shoeshiners offer extra services, such as shoe repairs and general tailoring. Some well-known people started their working life as shoeshiners, including singers and presidents.')),
    new Photograph('/photographs/2016-10-15_11-05/2016-10-15_11-05_005_large.webp', false,
      new Text('ConqueringAmerica.', 'Manhattan\n\nManhattan, known regionally as The City, is the most densely populated and geographically smallest of the five boroughs of New York City. It is the urban core of the New York metropolitan area. Manhattan has been described as the cultural, financial, media, and entertainment capital of the world, and the borough hosts the United Nations headquarters.')),
    new Photograph('/photographs/2016-10-15_11-05/2016-10-15_11-05_006_large.webp', false,
      new Text('ConqueringAmerica.', 'Statue of Liberty\n\nThe Statue of Liberty is a colossal neoclassical sculpture on Liberty Island in New York Harbor in New York City. The copper statue, a gift from the people of France to the people of the United States, was designed by French sculptor Frédéric Auguste Bartholdi and its metal framework was built by Gustave Eiffel. The statue was dedicated on October 28, 1886.')),
    new Photograph('/photographs/2016-10-15_11-05/2016-10-15_11-05_007_large.webp'),
    new Photograph('/photographs/2016-10-15_11-05/2016-10-15_11-05_008_large.webp', true,
      new Text('ConqueringAmerica.', 'Majestic Theatre\n\nOpened in 1927, the theater was designed by Herbert J. Krapp in a Spanish style and was built for real-estate developer Irwin S. Chanin. Both the facade and interior are New York City landmarks. In March 1987, Bernard B. Jacobs announced that the Andrew Lloyd Webber musical The Phantom of the Opera would be hosted at the Majestic. Phantom continued to be popular in the 21st century, and it became the longest-running show in Broadway history in 2006, surpassing the musical Cats.')),
    new Photograph('/photographs/2016-10-15_11-05/2016-10-15_11-05_009_large.webp', false,
      new Text('ConqueringAmerica.', 'Central Park\n\nBetween 1821 and 1855, New York City\'s population nearly quadrupled. As the city expanded northward up Manhattan Island, people were drawn to the few existing open spaces, mainly cemeteries, for passive recreation. These were seen as escapes from the noise and chaotic life in the city, which at the time was almost entirely centered on Lower Manhattan. The Commissioners\' Plan of 1811, the outline for Manhattan\'s modern street grid, included several smaller open spaces but not Central Park. As such, John Randel Jr. had surveyed the grounds for the construction of intersections within the modern-day park site.\n\nToday, Central Park is the most visited urban park in the United States and one of the most visited tourist attractions worldwide, with 42 million visitors in 2016.')),
    new Photograph('/photographs/2016-10-15_11-05/2016-10-15_11-05_010_large.webp', false,
      new Text('ConqueringAmerica.', 'Brighton Beach\n\nBrighton Beach is a neighborhood in the southern portion of the New York City borough of Brooklyn, within the greater Coney Island area. It is known for its high population of Russian-speaking immigrants, and as a summer destination for New York City residents due to its beaches along the Atlantic Ocean and its proximity to the amusement parks in Coney Island. ')),
    new Photograph('/photographs/2016-10-15_11-05/2016-10-15_11-05_011_large.webp', false,
      new Text('ConqueringAmerica.', 'National Air and Space Museum\n\nBecause of the museum\'s close proximity to the United States Capitol, the Smithsonian wanted a building that would be architecturally impressive but would not stand out too boldly against the Capitol building. St. Louis–based architect Gyo Obata of HOK designed the museum as four simple marble-encased cubes containing the smaller and more theatrical exhibits, connected by three spacious steel-and-glass atria which house the larger exhibits such as missiles, airplanes and spacecraft. The mass of the museum is similar to the National Gallery of Art across the National Mall, and uses the same pink Tennessee marble as the National Gallery. Built by Gilbane Building Company, the museum was completed in 1976. The west glass wall of the building is used for the installation of airplanes, functioning as a giant door.')),
    new Photograph('/photographs/2016-10-15_11-05/2016-10-15_11-05_012_large.webp', false,
      new Text('ConqueringAmerica.', 'National Air and Space Museum\n\nThe National Air and Space Museum of the Smithsonian Institution, also called the Air and Space Museum. It was established in 1946 as the National Air Museum and opened its main building on the National Mall near L\'Enfant Plaza in 1976. In 2018, the museum saw approximately 6.2 million visitors, making it the fifth most visited museum in the world, and the second most visited museum in the United States. The museum contains the Apollo 11 Command Module Columbia, the Friendship 7 capsule which was flown by John Glenn, Charles Lindbergh\'s Spirit of St. Louis, the Bell X-1 which broke the sound barrier, the model of the starship Enterprise used in the science fiction television show Star Trek: The Original Series, and the Wright brothers\' Wright Flyer airplane near the entrance.')),
    new Photograph('/photographs/2016-10-15_11-05/2016-10-15_11-05_013_large.webp', false,
      new Text('ConqueringAmerica.', 'Apollo–Soyuz\n\nApollo–Soyuz was the first crewed international space mission, carried out jointly by the Soviet Union and the United States in July 1975. Millions of people around the world watched on television as a Soviet Union Soyuz capsule docked with a United States Apollo spacecraft. The project, and its memorable handshake in space, was a symbol of détente between the two superpowers during the Cold War, and it is generally considered to mark the end of the Space Race, which had begun in 1957 with the Soviet Union\'s launch of Sputnik 1.')),
    new Photograph('/photographs/2016-10-15_11-05/2016-10-15_11-05_014_large.webp', true,
      new Text('ConqueringAmerica.', 'United States Capitol\n\nThe United States Capitol, often called The Capitol or the Capitol Building, is the meeting place of the United States Congress and the seat of the legislative branch of the U.S. federal government. Though no longer at the geographic center of the federal district, the Capitol forms the origin point for the district\'s street-numbering system and the district\'s four quadrants.')),
    new Photograph('/photographs/2016-10-15_11-05/2016-10-15_11-05_015_large.webp', false,
      new Text('ConqueringAmerica.', 'Lincoln Memorial\n\nThe Lincoln Memorial is a US national memorial built to honor the 16th president of the United States, Abraham Lincoln. The memorial\'s architect was Henry Bacon. The building is in the form of a Greek Doric temple and contains a large seated sculpture of Abraham Lincoln and inscriptions of two well-known speeches by Lincoln, the Gettysburg Address and his second inaugural address. The memorial has been the site of many famous speeches, including Martin Luther King Jr.\'s "I Have a Dream" speech delivered on August 28, 1963, during the rally at the end of the March on Washington for Jobs and Freedom.')),
    new Photograph('/photographs/2016-10-15_11-05/2016-10-15_11-05_016_large.webp', true,
      new Text('ConqueringAmerica.', 'Washington Monument\n\nThe Washington Monument is an obelisk within the National Mall built to commemorate George Washington, once commander-in-chief of the Continental Army (1775–1784) in the American Revolutionary War and the first President of the United States (1789–1797). Made of marble, granite, and bluestone gneiss is both the world\'s tallest predominantly stone structure and the world\'s tallest obelisk, standing 169.046 meters tall according to the U.S. National Geodetic Survey.')),
    new Photograph('/photographs/2016-10-15_11-05/2016-10-15_11-05_017_large.webp', false,
      new Text('ConqueringAmerica.', 'White House\n\nThe White House is the official residence and workplace of the president of the United States. It has been the residence of every U.S. president since John Adams in 1800. The term "White House" is often used as a metonym for the president and his advisers.\n\nThe residence was designed by Irish-born architect James Hoban in the neoclassical style. Hoban modelled the building on Leinster House in Dublin, a building which today houses the Oireachtas, the Irish legislature. Construction took place between 1792 and 1800, using Aquia Creek sandstone painted white.')),
    new Photograph('/photographs/2016-10-15_11-05/2016-10-15_11-05_018_large.webp', true,
      new Text('ConqueringAmerica.', 'Washington, D.C.\n\nWashington, D.C., formally the District of Columbia is the capital city and only federal district of the United States. The city was named for George Washington, a Founding Father and the first president of the United States, and the federal district is named after Columbia, a female personification of the nation. As the seat of the U.S. federal government and several international organizations, the city is an important world political capital. It is one of the most visited cities in the U.S., seeing over 20 million visitors in 2016.')),
    new Photograph('/photographs/2016-10-15_11-05/2016-10-15_11-05_019_large.webp', false,
      new Text('ConqueringAmerica.', 'National Mall\n\nThe long, grassy National Mall is home to iconic monuments including the Lincoln Memorial and the Washington Monument. At the eastern end is the domed U.S. Capitol, and the White House is to the north. It\'s also flanked by Smithsonian museums, and its lawns and pathways are often crowded with school groups, joggers, and softball teams. Nearby, the Tidal Basin reservoir is known for its blossoming cherry trees.')),
    new Photograph('/photographs/2016-10-15_11-05/2016-10-15_11-05_020_large.webp', true,
      new Text('ConqueringAmerica.', 'Statue of Abraham Lincoln\n\nAbraham Lincoln is a colossal seated figure of the 16th President of the United States Abraham Lincoln (1809–1865) sculpted by Daniel Chester French (1850–1931) and carved by the Piccirilli Brothers. The work follows in the Beaux Arts and American Renaissance style traditions.\n\nThe figure of Lincoln gazes directly ahead and slightly down with an expression of gravity and solemnity. His frock coat is unbuttoned, and a large United States flag is draped over the chair back and sides. French paid special attention to Lincoln\'s expressive hands, which rest on the enormous arms of a semi-circular ceremonial chair, the fronts of which bear fasces, emblems of authority from Roman antiquity. French used casts of his own fingers to achieve the correct placement.')),
    new Photograph('/photographs/2016-10-15_11-05/2016-10-15_11-05_021_large.webp', false,
      new Text('ConqueringAmerica.', 'Library of Congress\n\nThe Library of Congress is the research library that officially serves the United States Congress and is the de facto national library of the United States. It is the oldest federal cultural institution in the country. The library is housed in three buildings on Capitol Hill in Washington, D.C.; it also maintains a conservation center in Culpeper, Virginia. The library\'s functions are overseen by the Librarian of Congress, and its buildings are maintained by the Architect of the Capitol. The Library of Congress is one of the largest libraries in the world. Its "collections are universal, not limited by subject, format, or national boundary, and include research materials from all parts of the world and in more than 450 languages."')),
    new Photograph('/photographs/2016-10-15_11-05/2016-10-15_11-05_022_large.webp', false,
      new Text('ConqueringAmerica.', 'The Apotheosis of Washington\n\nThe Apotheosis of Washington is the fresco painted by Greek-Italian artist Constantino Brumidi in 1865 and visible through the oculus of the dome in the rotunda of the United States Capitol Building. The fresco is suspended 55 meters above the rotunda floor and covers an area of 433.3 square meters. The figures painted are up to 4.6 meters tall and are visible from the floor below. The dome was completed in 1863, and Brumidi painted it over the course of 11 months at the end of the Civil War. He was paid $40,000 ($676,261 in today\'s funds) for the fresco.\n\nBrumidi had worked for three years in the Vatican under Pope Gregory XVI, and served several aristocrats as an artist for palaces and villas, including the prince Torlonia. He immigrated to the United States in 1852, and spent much of the last 25 years of his life working in the Capitol. In addition to The Apotheosis of Washington he designed the Brumidi Corridors.')),
    new Photograph('/photographs/2016-10-15_11-05/2016-10-15_11-05_023_large.webp', false,
      new Text('ConqueringAmerica.', 'Benson Bridge\n\nThe Benson Bridge is the footbridge that traverses Multnomah Creek between the two cascades of Multnomah Falls. It has served as a distinguishing characteristic of the falls since it was built in 1914 by Simon Benson, one of the builders of the old Columbia highway. The bridge is probably the most photographed piece of architecture in Oregon and is a favorite of tourists from around the world.')),
    new Photograph('/photographs/2016-10-15_11-05/2016-10-15_11-05_024_large.webp', true,
      new Text('ConqueringAmerica.', 'Multnomah Falls\n\nMultnomah Falls is a waterfall located on Multnomah Creek in the Columbia River Gorge. Spanning two tiers on basalt cliffs, it is the tallest waterfall in the state of Oregon at 189 m in height. Multnomah Falls is the most visited natural recreation site in the Pacific Northwest with more than two million stopping by each year to take in the views. This is also one of the best places in the Columbia River Gorge National Scenic Area to study geology exposed by floods.')),
    new Photograph('/photographs/2016-10-15_11-05/2016-10-15_11-05_025_large.webp'),
    new Photograph('/photographs/2016-10-15_11-05/2016-10-15_11-05_026_large.webp', false,
      new Text('ConqueringAmerica.', 'Horsetail Falls Bridge on the Historic Columbia River Highway')),
    new Photograph('/photographs/2016-10-15_11-05/2016-10-15_11-05_027_large.webp', false,
      new Text('ConqueringAmerica.', 'The Columbia River is the largest river in the Pacific Northwest region of North America. The river is 2,000 km long, and its largest tributary is the Snake River. Its drainage basin is roughly the size of France and extends into seven US states and a Canadian province. The fourth-largest river in the United States by volume, the Columbia has the greatest flow of any North American river entering the Pacific.')),
    new Photograph('/photographs/2016-10-15_11-05/2016-10-15_11-05_028_large.webp', false,
      new Text('ConqueringAmerica.', 'Commonwealth Lake Park')),
    new Photograph('/photographs/2016-10-15_11-05/2016-10-15_11-05_029_large.webp', false,
      new Text('ConqueringAmerica.', 'T.A. Wilson Great Gallery\n\nThe T. A. Wilson Great Gallery is a 3 million-cubic-foot, six-story, glass-and-steel exhibit hall currently containing 39 full-size historic aircraft, including the nine-ton Douglas DC-3 hang from the space-frame ceiling in flight attitude. Dramatically lit at night, the Gallery is a stunning architectural achievement full of aircraft that trace the history of the first century of flight.')),
    new Photograph('/photographs/2016-10-15_11-05/2016-10-15_11-05_030_large.webp', false,
      new Text('ConqueringAmerica.', 'William E. Boeing Red Barn\n\nThe William E. Boeing Red Barn, with its warm wood tones and historic exhibits, reflects the romantic early days of aviation. The two-story barn is the historic birthplace of the Boeing Airplane Company. There is a warmth and a human scale to the simple, turn-of-the-century, post-and-beam architecture, while multiple sets of wooden mullioned windows and skylights bring in natural light.')),
    new Photograph('/photographs/2016-10-15_11-05/2016-10-15_11-05_031_large.webp', false,
      new Text('ConqueringAmerica.', 'J. Elroy McCaw Personal Courage Wing\n\nThe J. Elroy McCaw Personal Courage Wing is a two-story gallery that highlights the stories of courage, dedication, heroism, and the triumph of the human spirit of those involved in fighter aviation in World War I and World War II. This 8,200-square-meter addition opened on June 6, 2004 (the 60th anniversary of D-Day) and showcases 28 immaculately restored WWI and WWII fighter planes across two galleries, including one of the finest collections of historic fighters found anywhere in the world—the internationally known Champlin Fighter Collection.')),
    new Photograph('/photographs/2016-10-15_11-05/2016-10-15_11-05_032_large.webp', false,
      new Text('ConqueringAmerica.', 'Boeing VC-137B "Air Force One"\n\nThe first presidential jet plane, a specially built Boeing 707-153, is known as SAM (Special Air Mission) 970. This aircraft, as well as any other Air Force aircraft, carried the call sign "Air Force One" when the president was aboard. Delivered in 1959 to replace President Eisenhower\'s Super-Constellation, the high-speed jet transport was a flying Oval Office with a modified interior and sophisticated communication equipment. Eisenhower became the first U.S. president to fly by jet on SAM 970 on August 26, 1959.')),
    new Photograph('/photographs/2016-10-15_11-05/2016-10-15_11-05_033_large.webp', false,
      new Text('ConqueringAmerica.', 'Boeing VC-137B "Air Force One"\n\nSAM 970 was the first of three 707s acquired in 1959 as presidential aircraft, designated the VC-137 series. In addition to Eisenhower, SAM 970 has carried Presidents Kennedy, Johnson, and Nixon, as well as foreign leaders such as Nikita Khrushchev. In 1962, SAM 970 was replaced on primary presidential duty by a newer Boeing VC-137C. It was later used by Secretary of State Henry Kissinger for North Vietnamese peace talks in 1970-71, secret talks with the Chinese in 1971, and Middle East "shuttle diplomacy" in 1974. SAM 970 remained in the presidential fleet ferrying VIPs and the Vice-President until June of 1996.')),
    new Photograph('/photographs/2016-10-15_11-05/2016-10-15_11-05_034_large.webp', true,
      new Text('ConqueringAmerica.', 'Boeing VC-137B "Air Force One"\n\nOriginally introduced in 1958 with Pan American World Airways, the Boeing 707 is one of the world’s most iconic airplanes. The narrow-body airplane has made its way across the world while reaching countless commercial airlines in the process.')),
    new Photograph('/photographs/2016-10-15_11-05/2016-10-15_11-05_035_large.webp', true,
      new Text('ConqueringAmerica.', 'Space Needle\n\nThe Space Needle is an observation tower in Seattle. Considered to be an icon of the city, it has been designated a Seattle landmark. It was built in the Seattle Center for the 1962 World\'s Fair, which drew over 2.3 million visitors. The Space Needle features an observation deck 160 m above ground, providing views of the downtown Seattle skyline. On April 19, 1999, the city\'s Landmarks Preservation Board designated the tower a historic landmark.')),
    new Photograph('/photographs/2016-10-15_11-05/2016-10-15_11-05_036_large.webp', false,
      new Text('ConqueringAmerica.', 'Seattle, a city on Puget Sound in the Pacific Northwest, is surrounded by water, mountains, and evergreen forests, and contains thousands of acres of parkland. Washington State’s largest city, it’s home to large tech industry, with Microsoft and Amazon headquartered in its metropolitan area. The futuristic Space Needle, a 1962 World’s Fair legacy, is its most iconic landmark.')),
    new Photograph('/photographs/2016-10-15_11-05/2016-10-15_11-05_037_large.webp'),
    new Photograph('/photographs/2016-10-15_11-05/2016-10-15_11-05_038_large.webp', false, new Text('ConqueringAmerica.', 'Seattle Downtown at Night')),
    new Photograph('/photographs/2016-10-15_11-05/2016-10-15_11-05_039_large.webp', false,
      new Text('ConqueringAmerica.', 'Hawthorne Bridge\n\nThe Hawthorne Bridge is a truss bridge with a vertical lift that spans the Willamette River. It is the oldest vertical-lift bridge in operation in the United States and the oldest highway bridge in Portland. It is also the busiest bicycle and transit bridge in Oregon, with over 8,000 cyclists and 800 TriMet buses (carrying about 17,400 riders) daily. It was added to the National Register of Historic Places in November 2012.')),
    new Photograph('/photographs/2016-10-15_11-05/2016-10-15_11-05_040_large.webp', false,
      new Text('ConqueringAmerica.', 'The Hollywood Sign (originally the Hollywoodland Sign) is a landmark and cultural icon overlooking Hollywood. Spelling out the word Hollywood in 13.7 m-tall white capital letters and 106.7 m long, it was originally created in 1923 as a temporary advertisement for local real estate development, but due to increasing recognition the sign was left up, and replaced in 1978 with a more durable all-steel structure.')),
  ]);

export default  ConqueringAmerica;