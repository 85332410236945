import Album from '../core/photographyAlbum';
import Photograph from '../core/photograph';
import Text from '../core/text';
import Genre from '../core/genre';

const Stockholm = new Album(
  new Text('Stockholm.Title', 'Stockholm. Conquering Scandinavia'),
  Genre.Street,
  new Date('2022-09-01'),
  new Photograph('/photographs/2022-08-27_09-10/2022-08-27_09-10_032.webp'),
  [
   new Photograph('/photographs/2022-08-27_09-10/2022-08-27_09-10_001.webp', true, new Text('Stockholm.', 'Rådhuset Station')),
   new Photograph('/photographs/2022-08-27_09-10/2022-08-27_09-10_002.webp'),
   new Photograph('/photographs/2022-08-27_09-10/2022-08-27_09-10_003.webp'),
   new Photograph('/photographs/2022-08-27_09-10/2022-08-27_09-10_004.webp'),
   new Photograph('/photographs/2022-08-27_09-10/2022-08-27_09-10_005.webp', true, new Text('Stockholm.', 'T-Centralen')),
   new Photograph('/photographs/2022-08-27_09-10/2022-08-27_09-10_006.webp', false, new Text('Stockholm.', 'Stadion Centrum')),
   new Photograph('/photographs/2022-08-27_09-10/2022-08-27_09-10_007.webp', true),
   new Photograph('/photographs/2022-08-27_09-10/2022-08-27_09-10_008.webp', true),
   new Photograph('/photographs/2022-08-27_09-10/2022-08-27_09-10_009.webp', false, new Text('Stockholm.', 'Colorful Buildings in Old Town')),
   new Photograph('/photographs/2022-08-27_09-10/2022-08-27_09-10_010.webp', false, new Text('Stockholm.', 'Museum of Medieval')),
   new Photograph('/photographs/2022-08-27_09-10/2022-08-27_09-10_011.webp', true, new Text('Stockholm.', 'Brunkeberg Tunnel')),
   new Photograph('/photographs/2022-08-27_09-10/2022-08-27_09-10_012.webp', true),
   new Photograph('/photographs/2022-08-27_09-10/2022-08-27_09-10_013.webp', true, new Text('Stockholm.', 'Vasa Museum ')),
   new Photograph('/photographs/2022-08-27_09-10/2022-08-27_09-10_014.webp'),
   new Photograph('/photographs/2022-08-27_09-10/2022-08-27_09-10_015.webp'),
   new Photograph('/photographs/2022-08-27_09-10/2022-08-27_09-10_016.webp'),
   new Photograph('/photographs/2022-08-27_09-10/2022-08-27_09-10_017.webp'),
   new Photograph('/photographs/2022-08-27_09-10/2022-08-27_09-10_018.webp', false, new Text('Stockholm.', 'Gröna Lund')),
   new Photograph('/photographs/2022-08-27_09-10/2022-08-27_09-10_019.webp', false, new Text('Stockholm.', 'Nordiska Museet')),
   new Photograph('/photographs/2022-08-27_09-10/2022-08-27_09-10_020.webp'),
   new Photograph('/photographs/2022-08-27_09-10/2022-08-27_09-10_021.webp'),
   new Photograph('/photographs/2022-08-27_09-10/2022-08-27_09-10_022.webp'),
   new Photograph('/photographs/2022-08-27_09-10/2022-08-27_09-10_023.webp', true, new Text('Stockholm.', 'Kastellet')),
   new Photograph('/photographs/2022-08-27_09-10/2022-08-27_09-10_024.webp'),
   new Photograph('/photographs/2022-08-27_09-10/2022-08-27_09-10_025.webp', false, new Text('Stockholm.', 'Skyview')),
   new Photograph('/photographs/2022-08-27_09-10/2022-08-27_09-10_026.webp'),
   new Photograph('/photographs/2022-08-27_09-10/2022-08-27_09-10_027.webp', false, new Text('Stockholm.', 'Hammarbybacken Topplatå')),
   new Photograph('/photographs/2022-08-27_09-10/2022-08-27_09-10_028.webp'),
   new Photograph('/photographs/2022-08-27_09-10/2022-08-27_09-10_029.webp'),
   new Photograph('/photographs/2022-08-27_09-10/2022-08-27_09-10_030.webp'),
   new Photograph('/photographs/2022-08-27_09-10/2022-08-27_09-10_031.webp', true),
   new Photograph('/photographs/2022-08-27_09-10/2022-08-27_09-10_032.webp', false, new Text('Stockholm.', 'Old Town')),
   new Photograph('/photographs/2022-08-27_09-10/2022-08-27_09-10_033.webp', true),
   new Photograph('/photographs/2022-08-27_09-10/2022-08-27_09-10_034.webp'),
   new Photograph('/photographs/2022-08-27_09-10/2022-08-27_09-10_035.webp', true),
   new Photograph('/photographs/2022-08-27_09-10/2022-08-27_09-10_036.webp', true, new Text('Stockholm.', 'City Hall')),
   new Photograph('/photographs/2022-08-27_09-10/2022-08-27_09-10_037.webp', true),
   new Photograph('/photographs/2022-08-27_09-10/2022-08-27_09-10_038.webp'),
   new Photograph('/photographs/2022-08-27_09-10/2022-08-27_09-10_039.webp', true),
   new Photograph('/photographs/2022-08-27_09-10/2022-08-27_09-10_040.webp', true, new Text('Stockholm.', 'Uppsala')),
   new Photograph('/photographs/2022-08-27_09-10/2022-08-27_09-10_041.webp', true),
   new Photograph('/photographs/2022-08-27_09-10/2022-08-27_09-10_042.webp'),
  ]);

export default Stockholm;