import Album from '../core/photographyAlbum';
import Photograph from '../core/photograph';
import Text from '../core/text';
import Genre from '../core/genre';

const GoldenAge = new Album(
  new Text('GoldenAge.Title', 'Golden Age'),
  Genre.Street,
  new Date('2012-09-15'),
  new Photograph('/photographs/2012-09-14_15/2012-09-14_15_005_large.webp'),
  [
    new Photograph('/photographs/2012-09-14_15/2012-09-14_15_001_large.webp'),
    new Photograph('/photographs/2012-09-14_15/2012-09-14_15_002_large.webp'),
    new Photograph('/photographs/2012-09-14_15/2012-09-14_15_003_large.webp', true),
    new Photograph('/photographs/2012-09-14_15/2012-09-14_15_004_large.webp'),
    new Photograph('/photographs/2012-09-14_15/2012-09-14_15_005_large.webp'),
    new Photograph('/photographs/2012-09-14_15/2012-09-14_15_006_large.webp'),
    new Photograph('/photographs/2012-09-14_15/2012-09-14_15_007_large.webp'),
    new Photograph('/photographs/2012-09-14_15/2012-09-14_15_008_large.webp', true),
    new Photograph('/photographs/2012-09-14_15/2012-09-14_15_009_large.webp'),
    new Photograph('/photographs/2012-09-14_15/2012-09-14_15_010_large.webp', true),
    new Photograph('/photographs/2012-09-14_15/2012-09-14_15_011_large.webp'),
    new Photograph('/photographs/2012-09-14_15/2012-09-14_15_012_large.webp'),
    new Photograph('/photographs/2012-09-14_15/2012-09-14_15_013_large.webp'),
    new Photograph('/photographs/2012-09-14_15/2012-09-14_15_014_large.webp', true),
    new Photograph('/photographs/2012-09-14_15/2012-09-14_15_015_large.webp'),
    new Photograph('/photographs/2012-09-14_15/2012-09-14_15_016_large.webp'),
    new Photograph('/photographs/2012-09-14_15/2012-09-14_15_017_large.webp'),
    new Photograph('/photographs/2012-09-14_15/2012-09-14_15_018_large.webp'),
    new Photograph('/photographs/2012-09-14_15/2012-09-14_15_019_large.webp'),
    new Photograph('/photographs/2012-09-14_15/2012-09-14_15_020_large.webp'),
    new Photograph('/photographs/2012-09-14_15/2012-09-14_15_021_large.webp', true),
    new Photograph('/photographs/2012-09-14_15/2012-09-14_15_022_large.webp', true),
    new Photograph('/photographs/2012-09-14_15/2012-09-14_15_023_large.webp', true),
    new Photograph('/photographs/2012-09-14_15/2012-09-14_15_024_large.webp'),
    new Photograph('/photographs/2012-09-14_15/2012-09-14_15_025_large.webp'),
    new Photograph('/photographs/2012-09-14_15/2012-09-14_15_026_large.webp'),
    new Photograph('/photographs/2012-09-14_15/2012-09-14_15_027_large.webp'),
    new Photograph('/photographs/2012-09-14_15/2012-09-14_15_028_large.webp'),
    new Photograph('/photographs/2012-09-14_15/2012-09-14_15_029_large.webp'),
    new Photograph('/photographs/2012-09-14_15/2012-09-14_15_030_large.webp'),
    new Photograph('/photographs/2012-09-14_15/2012-09-14_15_031_large.webp', true),
    new Photograph('/photographs/2012-09-14_15/2012-09-14_15_032_large.webp'),
    new Photograph('/photographs/2012-09-14_15/2012-09-14_15_033_large.webp'),
    new Photograph('/photographs/2012-09-14_15/2012-09-14_15_034_large.webp'),
    new Photograph('/photographs/2012-09-14_15/2012-09-14_15_035_large.webp'),
    new Photograph('/photographs/2012-09-14_15/2012-09-14_15_036_large.webp'),
    new Photograph('/photographs/2012-09-14_15/2012-09-14_15_037_large.webp'),
    new Photograph('/photographs/2012-09-14_15/2012-09-14_15_038_large.webp'),
    new Photograph('/photographs/2012-09-14_15/2012-09-14_15_039_large.webp', true),
    new Photograph('/photographs/2012-09-14_15/2012-09-14_15_040_large.webp'),
    new Photograph('/photographs/2012-09-14_15/2012-09-14_15_041_large.webp'),
    new Photograph('/photographs/2012-09-14_15/2012-09-14_15_042_large.webp'),
    new Photograph('/photographs/2012-09-14_15/2012-09-14_15_043_large.webp'),
    new Photograph('/photographs/2012-09-14_15/2012-09-14_15_044_large.webp'),
    new Photograph('/photographs/2012-09-14_15/2012-09-14_15_045_large.webp'),
    new Photograph('/photographs/2012-09-14_15/2012-09-14_15_046_large.webp'),
  ]);

export default GoldenAge;